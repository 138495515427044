import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const SecondPage = () => (
  <Layout>
    <SEO title="Look Look" />
    <header className="looklook-header">
      <Link to="/">
        <h1>LOOK</h1>
        <h2>LOOK</h2>
      </Link>
    </header>
    <div className="information care">
      <h2>TATTOO AFTERCARE</h2>
      <span>
        {' '}
        Leave bandage on for at least 4 hours, but no longer than the following
        morning.
      </span>
      <span>
        {' '}
        Remove the bandage and wash the tattoo with mild antibacterial soap in
        warm water. Massage tattoo gently with{' '}
      </span>
      <span>fingertips and soapy water. DO NOT SCRUB THE TATTOO</span>
      <span>
        Let air dry, or pat dry with a clean towel. DO NOT RUB THE TATTOO. Make
        sure the tattoo is dry.
      </span>
      <span>
        With clean fingertips, apply a thin layer of ointment onto the tattoo.
        We recommend using Aquaphor Healing{' '}
      </span>
      <span>
        Ointment from Eucerin. Do not apply heavily, as your tattoo must be able
        to breathe.
      </span>
      <span>Re-bandage the tattoo with swan wrap.</span>
      <span>Repeat twice a day for 3-4 days. </span>
      <span>
        After the 3-4 days of re-bandaging, switch to an unscented lotion after
        showering. Use only as needed.
      </span>
      <h2>Tips</h2>
      <span>
        Do not submerge or soak the tattoo in water. No baths, hot tubs, pools,
        oceans, or lakes for 3-4 weeks.
      </span>
      <span>
        Less is more when applying ointment. Overuse of ointment/lotion. Overuse
        can cause breakouts around the tattoo area.
      </span>
      <span>
        Do not overexpose the tattoo to direct sunlight, or tanning beds, during
        the healing process.
      </span>
      <span>Do not rub, scratch, or pick at your tattoo while healing.</span>
      <span>If you have any questions, call or stop by the shop.</span>
    </div>
  </Layout>
);

export default SecondPage;
